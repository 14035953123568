import React, { Suspense, useMemo, useRef } from "react"
import{Vector2, Vector3, RGBFormat, EquirectangularReflectionMapping, TextureLoader, sRGBEncoding, ACESFilmicToneMapping} from "three"
import tw from 'twin.macro'
import { useGLTF, Html, Loader } from "@react-three/drei"
import { Canvas, useFrame, useThree } from "@react-three/fiber"
// eslint-disable-next-line import/no-webpack-loader-syntax
import logo from '!file-loader!./logo.glb'
//https://www.robfaucher.com/swirling-soap-bubble-rainbow-weirdness/
import Bubble from '../../images/bubble2.jpg'
import {Fur} from '../fur/Fur'

const vec = new Vector3()

const Rig=({mouse})=>{
  const { camera } = useThree()
  return useFrame(() => {
    camera.position.lerp(
      vec.set(mouse[0] * 2, mouse[1] * 2, camera.position.z),
      0.02
    )
  })
}

const Logo = ({position=[0,0,0], scale=[1,1,1]}) => {
  const bubbleTexture = useMemo(() => {
    const texture = new TextureLoader().load(Bubble);
    texture.mapping = EquirectangularReflectionMapping;
    texture.format = RGBFormat;
    texture.needsUpdate = true;
    return texture;
}, [])
  const { nodes, materials } = useGLTF(logo)
  return (
    <group dispose={null}>
      <mesh geometry={nodes.mesh_0.geometry} scale={scale} position={position}>
      <meshPhysicalMaterial attach="material" color={"#494949"} envMap={bubbleTexture} roughness={0} metalness={0} clearcoat={1} reflectivity={1} clearcoatRoughness={0} envMapIntensity={0.5}/>
      </mesh>
    </group>
  )
}
useGLTF.preload(logo)
const posValues = {
  value: 0,
  min: -100,
  max: 100,
  step: 0.1,
}
const scaleValues = {
  value: 0.1,
  min: 0.1,
  max: 50,
  step: 0.1,
}
const Scene = props => {
const ref = useRef(null)
return (
  <div
  css={[
    tw`h-screen flex flex-col items-center `,
  ]}
>
  <Canvas
    concurrent
    colorManagement
    dpr={[1,2]}
    camera={{ position: [0, 0, 25], near: 0.01, far: 40, fov: 60 }}
    gl={{
      powerPreference: "low-power",
      alpha: true,
      physicallyCorrectLights: true,
    }}
    onCreated={({ gl, scene }) => {
      gl.physicallyCorrectLights = true
      gl.toneMapping = ACESFilmicToneMapping
      gl.outputEncoding = sRGBEncoding
      gl.toneMappingExposure = 0.8
    }}
  >
    <fog attach="fog" args={["#fff0ea", 10, 60]} />
    <hemisphereLight position={[0,200,0]} groundColor={'#080820'}/>
    <directionalLight ref={ref} castShadow position={[100,58,-16]} intensity={5} />
    <Suspense
      fallback={
        <Html center>
          <Loader />
        </Html>
      }
    >
      <Fur shells={45} position={[0,-14.1,-2.9]} scale={[1,1,1]}/>
      <Logo position={[0,1,19.6]} scale={[0.125,0.125,0.125]}/>
    </Suspense>
  </Canvas>
  </div>

)}
export default Scene

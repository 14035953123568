import React, { Suspense, useRef, useState, useCallback } from "react"
import Layout from'../components/BasicLayout'
import { Link } from "gatsby"
import tw, { styled, css } from "twin.macro"
import LandingPage from '../components/LandingPage'
import {Helmet} from 'react-helmet';
import SEO from "../components/seo"
import '../components/basic.css'

const IndexPage = () => {
  return (
  <Layout>
    <SEO title="Follicle" />
    <Helmet>
      <style type="text/css">{`
        body {
          overflow: hidden;
        }
        html {
          overflow: hidden;
        }
    `}</style>
    </Helmet>

    <LandingPage destination="/toxi-cartography"/>
  </Layout>
)}
  
  export default IndexPage
import React, { useRef, useState, useEffect, useMemo} from "react"
import { useFrame } from '@react-three/fiber'
// eslint-disable-next-line import/no-webpack-loader-syntax
import SphereMap from '!file-loader!./spheremap.glb'
import * as THREE from "three"
import FurImg from './fur2.jpg'
import {
    Box, useGLTF, useTexture
  } from "@react-three/drei"

  const vertexShader = `
    uniform float offset;
    uniform float globalTime;
    uniform vec3 gravity;

    varying vec2 vUv;
    varying vec3 vNormal;

    const float spacing = 1.50;

    void main() {

        vec3 displacement = vec3(0.0,0.0,0.0);
        vec3 forceDirection = vec3(0.0,0.0,0.0);

        // "wind"
        forceDirection.x = sin(globalTime*0.7+position.x)*0.2;
        forceDirection.y = cos(globalTime*0.7+position.y)*0.2;
        forceDirection.z = sin(globalTime*0.7+position.z)*0.2;

        // "gravity"
        displacement = gravity + forceDirection;

        float displacementFactor = pow(offset, 3.0);

        vec3 aNormal = normal;
        aNormal.xyz += displacement*displacementFactor;

        // move outwards depending on offset(layer) and normal+force+gravity
        vec3 animated = vec3( position.x, position.y, position.z )+(normalize(aNormal)*offset*spacing);

        vNormal = normalize(normal*aNormal);

        vUv = uv*20.0;

        vec4 mvPosition = modelViewMatrix * vec4( animated, 1.0 );

        gl_Position = projectionMatrix * mvPosition;
  
              }
    `;
  const fragmentShader = `
    uniform sampler2D hairMap;
    uniform sampler2D colorMap;
    uniform vec3 color;
    uniform float offset;
  
    varying vec3 vNormal;
  
    varying vec2 vUv;
  
    void main() {
  
      vec4 hairColor = texture2D(hairMap, vec2(vUv.s, vUv.t));
      vec4 col = texture2D(colorMap, vec2(vUv.s*0.2, vUv.t*0.2));
  
      // discard no hairs + above the max length
      if (hairColor.a <= 0.0 || hairColor.g < offset) {
        discard;
      }
  
      // darker towards bottom of the hair
      float shadow = mix(0.0,hairColor.b*1.2,offset);
  
      // light
      vec3 light = vec3(0.5,2.0,0.3);
      float d = pow(max(0.25,dot(vNormal.xyz, light))*2.1, 1.4);
  
      gl_FragColor = vec4(color*col.xyz*d*shadow, 1.1-offset);
      
    }
    `;
const generateTexture=()=>{
    if(document){
    var canvas = document.createElement("canvas");
    canvas.width = 256;
    canvas.height = 256;
    var context = canvas.getContext("2d");
    context.fillStyle = "#FFF";
    for (var i = 0; i < 20000; ++i) {
      // r = hair 1/0
      // g = length
      // b = darkness
      context.fillStyle =
        "rgba(255," +
        Math.floor(Math.random() * 255) +
        "," +
        Math.floor(Math.random() * 255) +
        ",1)";
      context.fillRect(
        Math.random() * canvas.width,
        Math.random() * canvas.height,
        2,
        2
      );
    }
    const t = new THREE.CanvasTexture(context.canvas);
    t.wrapS = t.wrapT = THREE.RepeatWrapping;
    t.flipY = false;
    return t;
}
  }
  const shaderVars = {
    gravity : new THREE.Vector3(0,-0.75,0),
    shaderTime : 0.0,
    mouseObj : { x: 0.0, y: 0.0, vx: 0.0, vy: 0.0 },
}
export const Fur = ({shells, position=[0,0,0], scale=[1,1,1]})=>{
    const groupRef = useRef()
    const color = useTexture(FurImg)
    useEffect(() => {
        console.log("texture loaded")
        color.wrapS = color.wrapT = THREE.RepeatWrapping;
    }, [color])
    const [UVScaled, setUVScaled] = useState(false)
    useEffect(() => {
        setUVScaled(p=>true)
    }, [])
    const {current:texture} = useRef(generateTexture());
    const { nodes, materials } = useGLTF(SphereMap)
    const uniforms = useMemo(() => {
      let store = []
      for (let i = 0; i < shells; i++) {
        const uniform = {
          color: { type: "c", value: new THREE.Color(0xffffff)},
          hairMap: { type: "t", value: texture},
          colorMap: { type: "t", value: color},
          offset: { type: "f", value: i / shells},
          globalTime: { type: "f", value: shaderVars.shaderTime},
          gravity: { type: "v3", value: shaderVars.gravity}
        };
        store.push(uniform)
    }
  return store;
  }, [shells])
    useFrame(({mouse, camera}, delta) => {
      let d = delta*1000
      if (isNaN(d) || d > 1000 || d == 0) {
        d = 1000 / 60
      }
      let optimalDivider = d / 16
      let smoothing = Math.max(4, 20 / optimalDivider)
      let xf = (mouse.x - shaderVars.mouseObj.x) / (smoothing * 5)
      let yf = (mouse.y - shaderVars.mouseObj.y) / (smoothing * 5)
      shaderVars.mouseObj.vx += xf
      shaderVars.mouseObj.vy += yf
      shaderVars.mouseObj.vx *= 0.96
      shaderVars.mouseObj.vy *= 0.94
      shaderVars.mouseObj.x += shaderVars.mouseObj.vx
      shaderVars.mouseObj.y += shaderVars.mouseObj.vy
      shaderVars.gravity.x = -(mouse.x - shaderVars.mouseObj.x) * 2
      var dif = Math.sin(mouse.x) * 150 - camera.position.x
      shaderVars.gravity.y = -0.75 + Math.abs(dif) / 150 - (mouse.y - shaderVars.mouseObj.y) * 2
      shaderVars.shaderTime += d * 0.005
      groupRef.current?.traverse(child =>{
          if(child.isMesh && child.material !== undefined){
            child.material.uniforms.globalTime.value = shaderVars.shaderTime
          }
      })
    })
    return(
<>
<group ref={groupRef}>
{Object.keys(nodes).map((node,idx) => {
    let children = []
    let geo = nodes[node].geometry
    let uvs = geo.attributes.uv.array
    if(!UVScaled)
    {
        for (let i = 0, len = uvs.length; i < len; i++) {
        uvs[i] *= 1.5;
      }
    }
      for (let i = 0; i < shells; i++) {
    children.push(
    <mesh geometry={geo.clone()} scale={scale} position={position} matrixAutoUpdate={true} frustumCulled={false} key={`${node}_${i}_mesh`} >
      <shaderMaterial transparent={true} uniforms={uniforms[i]} vertexShader={vertexShader} fragmentShader={fragmentShader} />
    </mesh>
    )
    }
    return( 
    <React.Fragment key={`${node}_${idx}_base`}>
    {children}
    </React.Fragment>
    )
}
)}
</group>
</>
    )
}
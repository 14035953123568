import React, { Suspense, useRef, useMemo, useState, useEffect, useCallback } from "react"
import tw from "twin.macro"
import styled, { css } from "styled-components/macro"
import Splash from "../images/Splash.jpeg"
import { Link } from "gatsby"
import { VolumeOff, VolumeUp } from "@styled-icons/material-rounded"
import { Wiggle } from "../components/Wiggle"
import Scene from "./landing/SceneSetup"
import { proxy, useSnapshot } from "valtio"
import state from '../components/Store'
import {cssNavBaseText,cssNavButton} from '../utils/cssVars'
import {isClient} from '../utils/common'

const LandingPage = (props)=>{
  const [mouse, setMouse] = useState([0,0])
  // const onMouseMove = useCallback(({ clientX: x, clientY: y }) => setMouse((p)=>(isClient ? [(x / window.innerWidth) * 2 - 1, -(y / window.innerHeight) * 2 + 1] : [0,0])), [isClient])
  const snap = useSnapshot(state)
  return(
  <>
    <div
      css={[
        tw`h-screen flex flex-col items-center `,
      ]}
      // onPointerMove={onMouseMove}
    >
      {/* <img tw="absolute object-cover w-full h-full z-index[-1]" src={Splash} /> */}
      <div tw="h-1/2 w-full -ms-flex-item-align[start]">
        <Scene mouse={mouse} />
      </div>

      <div
        tw="pointer-events-none flex flex-col justify-center h-full justify-items[flex-end]"
        css={[
          css`
            position: absolute;
          `,
        ]}
      >

        <div tw="pointer-events-none mt-auto mb-6 px-10 transform hocus:scale-105 transition-transform duration-75 flex justify-center align-items[center] " 
        >
          <div tw="pointer-events-auto px-3 py-1 flex[0 0 auto] shadow-md"  
                css={[cssNavBaseText, cssNavButton]}>
                  <Link to="/toxi-cartography">Enter</Link>
                </div>
                </div>

        <div tw={"margin-bottom[20vh] px-6 flex justify-center align-items[center] text-gray-50 text-xl"}>
        <Wiggle rotation={20} timing={200} customStyle={tw`flex align-content[center] justify-center`}>
          {snap.playSong ? 
        <button tw={"pointer-events-auto w-7 h-7"} title={"Mute"} onClick={()=>(state.playSong = false)}><VolumeUp/></button>
        :<button tw={"pointer-events-auto w-7 h-7"} title={"Unmute"} onClick={()=>( state.playSong = true)}><VolumeOff/></button>}
        </Wiggle>
        </div>
      </div>
    </div>
  </>
  )
      }
export default LandingPage
